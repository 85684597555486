import { gsap } from "gsap";
import React, { forwardRef, useEffect, useState } from "react";

const Header = (props, ref) => {
	let [hasTouch, setHasTouch] = useState(false);

	const isTouchDevice = () =>
		"ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

	useEffect(() => {
		setHasTouch(isTouchDevice());
		gsap.fromTo(ref.current, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.8, ease: "Power2.inOut" });
	});

	return (
		<div className="header" ref={ref}>
			<h1 className="header__heading">Ideas Report</h1>
			<p className="header__intro">
				Ideas are a messy business. There are dead ends, wrong turns, and all manner of false starts. To get our heads around them, we created the WeTransfer Ideas Report—an annual roundup of insights from all over the globe about the unpredictable nature of creativity.
			</p>
			<div className="header__scroll-indicator">
				<p>{hasTouch ? "Swipe" : "Scroll"}</p>
				<svg
					className={hasTouch ? "rotate" : ""}
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M-4.58307e-07 13.5156L-5.90766e-07 10.4853L18.1818 10.4853L9.84848 2.152L12 0.000487757L24 12.0005L12 24.0005L9.84848 21.849L18.1818 13.5156L-4.58307e-07 13.5156Z"
						fill="white"
					/>
				</svg>
			</div>
		</div>
	);
};

export default forwardRef(Header);
