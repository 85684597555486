import React, { useRef } from "react";

import Image2022 from "static/img/2022.jpg";
import Image2021 from "static/img/2021.jpg";
import Image2020 from "static/img/2020.png";
import Image2018 from "static/img/2018.png";

import Pearl1 from "static/img/pearl-1.png";
import Pearl2 from "static/img/pearl-2.png";
import Pearl3 from "static/img/pearl-3.png";
import Pearl4 from "static/img/pearl-4.png";

import Shape1 from "static/img/shape-1.png";
import Shape2 from "static/img/shape-2.png";
import Shape3 from "static/img/shape-3.png";

import Orbit from "static/img/orbit.png";

const Report = ({
  reportImage,
  reportYear,
  reportDescription,
  reportPullOut,
  reportUrl,
  expansion,
  pearls = null,
  shapes = null,
  hash,
}) => {
  const handleClick = () => {
    expansion();
  };
  return (
    <div
      className="report"
      onClick={handleClick}
      data-tracking-event="trackStructEvent"
      data-tracking-action={`click_report_card_${hash}`}
      data-tracking-label={`report_card_${hash}`}
    >
      {hash === "2019" && (
        <div className="report__borders">
          <div className="report__borders__full" />
          <div className="report__borders__canvas" />
        </div>
      )}
      <div className="report__line" />
      <div className={`report__canvas${hash === '2022' ? ' report__canvas--border' : ''}`}>
        <div className="report__canvas__inner">
          {hash === "2018" ? (
            <img
              src={Image2018}
              alt={`Report ${hash} image`}
              className="report__image"
            />
          ) : hash === "2022" ? (
            <img
              src={Image2022}
              alt={`Report ${hash} image`}
              className="report__image"
            />
          ) : hash === "2021" ? (
            <>
              <img
                src={Image2021}
                alt={`Report ${hash} image`}
                className="report__image"
              />
              <img src={Orbit} alt="Orbit" className="report__image--orbit" />
            </>
          ) : hash === "2020" ? (
            <img
              src={Image2020}
              alt={`Report ${hash} image`}
              className="report__image"
            />
          ) : (
            <>
              <div className="report__canvas__shape" key="1">
                <img src={Shape1} alt="" />
              </div>
              <div className="report__canvas__shape" key="2">
                <img src={Shape2} alt="" />
              </div>
              <div className="report__canvas__shape" key="3">
                <img src={Shape3} alt="" />
              </div>
            </>
          )}
        </div>
      </div>
      <div className="report__content">
        <a
          href={`https://wetransfer.com/ideas-report/${hash}/#download`}
          className={`report__pdf report__pdf--${hash}`}
          data-tracking-event="trackStructEvent"
          data-tracking-action={`click_download_pdf_${hash}`}
          data-tracking-label={`download_pdf_${hash}`}
        >
          Get the .PDF
        </a>
        <a
          href={reportUrl}
          className="report__view"
          data-tracking-event="trackStructEvent"
          data-tracking-action={`click_view_ideasreport_${hash}`}
          data-tracking-label={`view_ideasreport_${hash}`}
        >
          <span>View</span>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-4.58307e-07 13.5156L-5.90766e-07 10.4853L18.1818 10.4853L9.84848 2.152L12 0.000487757L24 12.0005L12 24.0005L9.84848 21.849L18.1818 13.5156L-4.58307e-07 13.5156Z"
              fill="white"
            />
          </svg>
        </a>
        <h2 className="report__title">{reportYear} Report</h2>
        <p className="report__description">{reportDescription}</p>
        <span className="report__pullout">{reportPullOut}</span>
      </div>

      {pearls && (
        <div className="report__pearls">
          <div className="report__pearl" key="1">
            <img src={Pearl1} alt="" />
          </div>
          <div className="report__pearl" key="2">
            <img src={Pearl2} alt="" />
          </div>
          <div className="report__pearl" key="3">
            <img src={Pearl3} alt="" />
          </div>
          <div className="report__pearl" key="4">
            <img src={Pearl4} alt="" />
          </div>
        </div>
      )}

      {shapes && (
        <div className="report__shapes">
          <div className="report__shape" key="1">
            <img src={Shape1} alt="" />
          </div>
          <div className="report__shape" key="2">
            <img src={Shape2} alt="" />
          </div>
          <div className="report__shape" key="3">
            <img src={Shape3} alt="" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Report;
