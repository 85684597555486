import { gsap } from "gsap";
import debounce from "lodash.debounce";
import React, { useEffect, useRef } from "react";
import Report from "./report";
import Emitter from "../utils/emitter";

const Reports = ({ headerRef }) => {
  const thumb = useRef();
  const progress = useRef();
  const container = useRef();
  const reportsContainer = useRef();

  const ideasReports = [
    {
      reportImage: "/img/2022.jpg",
      reportYear: "2022",
      hash: "2022",
      reportUrl: "https://wetransfer.com/ideas-report/2022",
      reportDescription:
        "Does the dream creative job really exist?",
      reportPullOut:
        "With growing numbers of creatives feeling burnt out, underpaid, and overwhelmed by the industry, this year’s report examines the tension between perception and reality. We speak to 6,500 creatives about the very real cost of their dream jobs, and why the next generation are prioritizing joy.",
    },
    {
      reportImage: "/img/2021.jpg",
      reportYear: "2021",
      hash: "2021",
      reportUrl: "https://wetransfer.com/ideas-report/2021",
      reportDescription:
        "Has the creative world as we know it changed for good?",
      reportPullOut:
        "With a glimpse of normalcy appearing on the horizon, we take a closer look at the world we’re returning to and uncover some seismic shifts in creative power. 10,000 people help us paint a picture of new leaders, new challenges, and new ideas.",
    },
    {
      reportImage: "/img/2020.png",
      reportYear: "2020",
      hash: "2020",
      reportUrl: "https://wetransfer.com/ideas-report/2020",
      reportDescription: "What does a global pandemic do to a creative mind?",
      reportPullOut:
        "Turns out when the going gets tough, the tough get creative. In a year defined by rapid change (understatement of the century) 35,000 creatives tell us how they found ways to connect, to create, and to challenge their doubt.",
      pearls: [
        "/img/pearl-1.png",
        "/img/pearl-2.png",
        "/img/pearl-3.png",
        "/img/pearl-4.png",
      ],
    },
    {
      reportImage: "/img/2019.png",
      reportYear: "2019",
      hash: "2019",
      reportUrl: "https://wetransfer.com/ideas-report/2019",
      reportDescription:
        "Does a great idea mean money in the bank or a better-looking planet?",
      reportPullOut:
        "Having an idea is only half the battle. Once the lightning bolt of inspiration has hit, that’s when the real work begins. From saving pennies to saving the planet, we ask 20,000 creatives about their motivations, their distractions, and what makes an idea worth pursuing.",
      shapes: ["/img/shape-1.png", "/img/shape-2.png", "/img/shape-3.png"],
    },
    {
      reportImage: "/img/2018.png",
      reportYear: "2018",
      hash: "2018",
      reportUrl: "https://wepresent.wetransfer.com/story/ideas-report-2018/#/",
      reportPullOut:
        "From magazines and books to conferences and apps, conversations around creativity have always been quite one-sided. So in 2018 we created the first ever WeTransfer Ideas Report, asking 10,000 creatives about how, when, and where they get their best ideas.",
      reportDescription: "Can ideas really strike anywhere, at any time?",
    },
  ];

  let expansionRatio = 1;

  let items = [];
  let rafId = null;
  let maxScroll = 0;
  let snapArray = [];
  let closeTl = null;
  let animating = false;
  let isResizing = false;
  let reportClickTl = null;
  let expandedIndex = null;
  const dom = { siteContent: null };
  let isScrollEffectActive = false;
  const scroll = { target: 0, current: 0, ease: 0.1 };
  const animDimension = { default: 0, expanded: 0, diff: 0 };
  const cache = { ww: 0, wh: 0, reportsWidth: 0, progressBounds: null };

  const resize = () => {
    isResizing = true;

    document.body.style.overflow = "";
    scroll.current = scroll.target = 0;
    window.scrollTo(0, 0);
    reportsContainer.current.style.transform = ``;

    const reportItems = [...document.querySelectorAll(".reports__item")];

    cache.ww = window.innerWidth;
    cache.wh = window.innerHeight;
    cache.reportsWidth = reportsContainer.current.scrollWidth;
    dom.siteContent = document.querySelector(".site__content");
    dom.header = document.querySelector(".site__content .header");

    expansionRatio = cache.ww > 768 ? 1064 / 538 : 768 / 587;
    isScrollEffectActive = cache.ww > 414;

    items = reportItems.map((el, index) => {
      const pdf = el.querySelector(".report__pdf");
      const view = el.querySelector(".report__view");
      const pullOut = el.querySelector(".report__pullout");
      const description = el.querySelector(".report__description");
      const button = el.querySelector(".reports__item__cross");

      gsap.set([el, pdf, view, button], { clearProps: "all" });

      const data = {
        el,
        pdf,
        view,
        pullOut,
        description,
        button,
        bounds: el.getBoundingClientRect(),
      };

      if (index == 2) {
        data.pearls = el.querySelector(".report__pearls");
      }
      if (index == 3) {
        data.shapes = el.querySelector(".report__shapes");
        data.borders = el.querySelector(".report__borders");
      }
      return data;
    });

    dom.siteContent.style.height = ``;
    thumb.current.style.width = ``;
    thumb.current.style.transform = ``;

    dom.header.style.filter = ``;
    dom.header.style.opacity = 1;

    if (isScrollEffectActive) {
      animDimension.default = items[0].bounds.width;
      animDimension.expanded = items[0].bounds.width * expansionRatio;
      animDimension.diff = (animDimension.expanded - animDimension.default) / 2;

      snapArray = items.map(
        ({ bounds }) => bounds.x - cache.ww / 2 + bounds.width / 2
      );
      maxScroll = cache.reportsWidth + cache.wh - cache.ww;
      dom.siteContent.style.height = `${maxScroll}px`;

      cache.progressBounds = progress.current.getBoundingClientRect();
      thumb.current.style.width = `${
        (cache.progressBounds.width / cache.reportsWidth) *
        cache.progressBounds.width
      }px`;
      cache.thumbBounds = thumb.current.getBoundingClientRect();

      if (expandedIndex !== null) {
        setPosition(expandedIndex);
      }
    }

    isResizing = false;
  };

  const nextIndex = (index) => {
    return index + 1 < items.length ? index + 1 : index;
  };

  const prevIndex = (index) => {
    return index - 1 >= 0 ? index - 1 : index;
  };

  const onKeyPress = (ev) => {
    const { keyCode } = ev;

    if (expandedIndex == null || !isScrollEffectActive) return;
    let index = null;
    switch (keyCode) {
      // Escape
      case 27:
        handleReportClose();
        break;
      // Left
      case 37:
        index = prevIndex(expandedIndex);
        handleReportExpansion(index);
        break;
      // Right
      case 39:
        index = nextIndex(expandedIndex);
        handleReportExpansion(index);
        break;
      // Up
      case 38:
        index = prevIndex(expandedIndex);
        handleReportExpansion(index);
        break;
      // Down
      case 40:
        index = nextIndex(expandedIndex);
        handleReportExpansion(index);
        break;

      default:
        break;
    }
  };

  const addEventListeners = () => {
    Emitter.on("nav:click", handleNavigationClick);
    window.addEventListener("wheel", debounce(onScroll, 250));
    window.addEventListener("touchend", debounce(onScroll, 500));
    window.addEventListener("resize", debounce(resize, 500));
    window.addEventListener("keydown", onKeyPress);
  };

  const removeEventListeners = () => {
    Emitter.off("nav:click", handleNavigationClick);
    window.removeEventListener("wheel", debounce(onScroll, 250));
    window.removeEventListener("touchend", debounce(onScroll, 250));
    window.removeEventListener("resize", debounce(resize, 250));
    window.removeEventListener("keydown", onKeyPress);
  };

  const onScroll = () => {
    if (!isScrollEffectActive) return;
    const scrollPos = window.pageYOffset;
    const startPos = items[0].bounds.x;
    const endPos = items[items.length - 1].bounds.right;

    if (
      scrollPos >= startPos - cache.ww / 2 &&
      scrollPos <= endPos - cache.ww / 2
    ) {
      const snapX = gsap.utils.snap(snapArray, scrollPos);
      window.scrollTo(0, snapX);
    }
  };

  const clamp = (value, min, max) => {
    return Math.min(Math.max(value, min), max);
  };

  const tick = () => {
    if (!isResizing && isScrollEffectActive) {
      const scrollPos = window.pageYOffset;

      if (!animating && expandedIndex == null) {
        scroll.current += (scrollPos - scroll.current) * scroll.ease;
        reportsContainer.current.style.transform = `translate3d(${
          scroll.current * -1
        }px, 0, 0)`;
      }

      const ratio = scroll.current / (maxScroll - cache.wh);
      thumb.current.style.transform = `translate3d(${
        (cache.progressBounds.width - cache.thumbBounds.width) * ratio
      }px, 0, 0)`;

      const ratio2 = scrollPos / items[0].bounds.x;
      dom.header.style.filter = `blur(${clamp(ratio2 * 20, 0, 20)}px)`;
      dom.header.style.opacity = 1 - clamp(ratio2 * 1.5, 0, 0.75);
    }

    rafId = requestAnimationFrame(tick);
  };

  const checkHash = (hash = null) => {
    let data = null;
    if (hash !== null) {
      const hashedParsed = hash.replace("#", "");
      data = ideasReports.findIndex(
        (ideasReport) => ideasReport.hash == hashedParsed
      );
    } else {
      const locationHash = location.hash;
      const hashedParsed = locationHash.replace("#", "");
      data = ideasReports.findIndex(
        (ideasReport) => ideasReport.hash == hashedParsed
      );
    }
    return data;
  };

  const handleNavigationClick = (hash) => {
    const index = checkHash(hash);
    setPosition(index);
  };

  const setPosition = (index) => {
    handleReportExpansion(index, true, true);
  };

  const handleReportExpansion = (index, set = false, force = false) => {
    const hash = `#${ideasReports[index].hash}`;

    if (history.pushState) {
      history.pushState(null, null, hash);
    } else {
      location.hash = hash;
    }

    if (!isScrollEffectActive) {
      if (set) {
        const elem = items[index];
        window.scrollTo(0, elem.bounds.top - 80);
      }

      window.location.replace(ideasReports[index].reportUrl);
    } else {
      expandedIndex = index;
      document.body.style.overflow = "hidden";

      reportClickTl && reportClickTl.kill();
      reportClickTl = null;

      closeTl && closeTl.kill();
      closeTl = null;

      const item = items[index];
      const elem = item.el;
      const { pdf } = item;
      const { view } = item;
      const { pullOut } = item;
      const { description } = item;
      const { button } = item;
      const otherElems = items
        .filter((item, itemIndex) => index !== itemIndex)
        .map(({ el }) => el);
      const otherPdfs = items
        .filter((item, itemIndex) => index !== itemIndex)
        .map(({ pdf }) => pdf);
      const otherViews = items
        .filter((item, itemIndex) => index !== itemIndex)
        .map(({ view }) => view);
      const otherButtons = items
        .filter((item, itemIndex) => index !== itemIndex)
        .map(({ button }) => button);

      const snapX = snapArray[index];
      const scrollTarget = snapX + animDimension.diff;

      item.el.classList.add("is-expanded");

      if (set) {
        gsap.set(otherElems, {
          force3D: true,
          width: animDimension.default,
          opacity: 0.1,
        });
        gsap.set([otherButtons, otherPdfs, otherViews], { autoAlpha: 0 });
        gsap.set(elem, {
          force3D: true,
          width: animDimension.expanded,
          opacity: 1,
        });
        gsap.set([button, pdf, view, pullOut], { autoAlpha: 1 });
        gsap.set(progress.current, { opacity: 0.1 });
        index === 2 && gsap.set(item.pearls, { autoAlpha: 1 });

        if (index === 3) {
          gsap.set(item.shapes, { autoAlpha: 1 });
          gsap.set(item.borders, { autoAlpha: 1 });
        }

        scroll.current = scroll.target = scrollTarget;
        window.scrollTo(0, scroll.current);
        reportsContainer.current.style.transform = `translate3d(${
          scroll.current * -1
        }px, 0, 0)`;

        animating = false;
      } else {
        reportClickTl = gsap
          .timeline({
            paused: true,
            onComplete: () => {
              animating = false;
            },
          })
          .to(progress.current, { opacity: 0.1, duration: 0.5 }, 0)
          .to(
            otherElems,
            {
              force3D: true,
              width: animDimension.default,
              opacity: 0.1,
              duration: 0.5,
            },
            0
          )
          .to([otherPdfs, otherViews], { autoAlpha: 0, duration: 0.2 }, 0)
          .to([otherButtons, description], { autoAlpha: 0, duration: 0.5 }, 0)
          .to(
            elem,
            {
              force3D: true,
              width: animDimension.expanded,
              opacity: 1,
              duration: 0.5,
            },
            0
          )
          .to(button, { autoAlpha: 1, duration: 0.5 }, 0)
          .to([pullOut], { display: "block" })
          .to([pdf, view, pullOut], { autoAlpha: 1, duration: 0.5 }, 0.3)
          .to(
            scroll,
            {
              current: scrollTarget,
              target: scrollTarget,
              duration: 0.5,
              onUpdate: () => {
                window.scrollTo(0, scroll.current);
                reportsContainer.current.style.transform = `translate3d(${
                  scroll.current * -1
                }px, 0, 0)`;
              },
            },
            0
          );

        reportClickTl.to(
          items[2].pearls,
          { autoAlpha: index === 2 ? 1 : 0, duration: 0.2 },
          index === 1 ? 0.5 : 0
        );
        reportClickTl.to(
          items[3].shapes,
          { autoAlpha: index === 3 ? 1 : 0, duration: 0.2 },
          index === 2 ? 0.5 : 0
        );
        reportClickTl.to(
          items[3].borders,
          { autoAlpha: index === 3 ? 1 : 0, duration: 0.2 },
          0
        );
        reportClickTl.play();
      }
    }
  };

  const handleReportClose = () => {
    if (!isScrollEffectActive) {
    } else {
      reportClickTl && reportClickTl.kill();
      reportClickTl = null;

      closeTl && closeTl.kill();
      closeTl = null;

      const elems = items.map(({ el }) => el);
      const buttons = items.map(({ button }) => button);
      const pdfs = items.map(({ pdf }) => pdf);
      const pullOut = items.map(({ pullOut }) => pullOut);
      const description = items.map(({ description }) => description);
      const views = items.map(({ view }) => view);

      const snapX = snapArray[expandedIndex];
      const scrollTarget = snapX;

      for (const item of items) {
        if (item.el.classList.contains("is-expanded")) {
          item.el.classList.remove("is-expanded");
        }
      }

      if (history.pushState) {
        history.pushState(
          "",
          document.title,
          window.location.pathname + window.location.search
        );
      } else {
        // Prevent scrolling by storing the page's current scroll offset
        const scrollV = document.body.scrollTop;
        const scrollH = document.body.scrollLeft;

        window.location.hash = "";

        // Restore the scroll offset, should be flicker free
        document.body.scrollTop = scrollV;
        document.body.scrollLeft = scrollH;
      }

      closeTl = gsap
        .timeline({
          paused: true,
          onComplete: () => {
            animating = false;
            expandedIndex = null;
            document.body.style.overflow = "";
          },
        })
        .to([pullOut], { display: "none" })
        .to([pdfs, views, pullOut], { autoAlpha: 0, duration: 0.2 }, 0)
        .to(
          [items[2].pearls, items[3].shapes],
          { autoAlpha: 0, duration: 0.2 },
          0
        )
        .to(
          elems,
          {
            force3D: true,
            width: animDimension.default,
            opacity: 1,
            duration: 0.5,
          },
          0.1
        )
        .to(
          [progress.current, description],
          { autoAlpha: 1, duration: 0.5 },
          0.1
        )
        .to(buttons, { autoAlpha: 0, duration: 0.5 }, 0.1)
        .to(
          scroll,
          {
            current: scrollTarget,
            target: scrollTarget,
            duration: 0.5,
            onUpdate: () => {
              window.scrollTo(0, scroll.current);
              reportsContainer.current.style.transform = `translate3d(${
                scroll.current * -1
              }px, 0, 0)`;
            },
          },
          0.1
        );

      closeTl.play();
    }
  };

  const goToStart = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    resize();
    addEventListeners();

    const index = checkHash();
    index > -1 && setPosition(index);

    gsap.fromTo(
      container.current,
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 0.8, ease: "Power2.inOut" }
    );

    tick();
    addEventListeners();
    return () => {
      removeEventListeners();
      rafId && cancelAnimationFrame(rafId);
    };
  });

  return (
    <div className="reports" ref={container}>
      <div className="reports__inner">
        <ul className="reports__list" ref={reportsContainer} role="list">
          <li className="reports__spacer" />
          {ideasReports.map((ideasReport, index) => (
            <li className="reports__item" key={ideasReport.reportYear}>
              <button
                className="reports__item__cross"
                onClick={handleReportClose}
              >
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.5003 16.1928L2.87485 26.8199L0.185388 24.1305L10.8104 13.5035L0 2.69477L2.68939 0.00530767L13.4997 10.8136L24.3112 0L27 2.69077L16.1896 13.5029L26.8199 24.1311L24.1291 26.8199L13.5003 16.1928Z"
                    fill="white"
                  />
                </svg>
              </button>
              <Report
                expansion={() => handleReportExpansion(index)}
                reportImage={ideasReport.reportImage}
                reportYear={ideasReport.reportYear}
                reportDescription={ideasReport.reportDescription}
                reportPullOut={ideasReport.reportPullOut}
                hash={ideasReport.hash}
                reportUrl={ideasReport.reportUrl}
                pearls={ideasReport.pearls}
                shapes={ideasReport.shapes}
              />
            </li>
          ))}
          <li className="reports__ending">
            <button className="reports__ending__cta" onClick={goToStart}>
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24 10.4858L24 13.5161L5.81818 13.5161L14.1515 21.8495L12 24.001L-5.24537e-07 12.001L12 0.000976038L14.1515 2.15249L5.81818 10.4858L24 10.4858Z"
                  fill="white"
                />
              </svg>
              <p>Back to start</p>
            </button>
          </li>
        </ul>
      </div>
      <div className="reports__progress" ref={progress}>
        <div className="reports__progress__thumb" ref={thumb} />
      </div>
    </div>
  );
};

export default Reports;
